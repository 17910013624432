<div class="overlay" *ngIf="isLoading"></div>
<p-progressSpinner
  *ngIf="isLoading"
  ariaLabel="loading"
  class="loading-spinner"
></p-progressSpinner>
<div class="layout-topbar">
  <div class="topbar-container w-full">
    <a (click)="onLogoClick()" class="logo cursor-pointer">
      <img
        src="/assets/images/svg/logo-ribera-menu.svg"
        height="40"
        class="mr-2"
        alt="Logo"
      />
    </a>

    <!-- Botones solo en modo celular -->
    <div class="mobile-buttons" *ngIf="isMobile && !user && !tokenStorage ">
      <button (click)="login()" class="btn-login">Iniciar sesión</button>
      <button (click)="reserve()" class="btn-reserve">Reservar</button>
    </div>

    <!-- Menú para no autenticados -->
    <ng-container *ngIf="!user && !tokenStorage">
      <div class="menu-left" [ngClass]="{ 'menu-slide-in-active': isMenuOpen }">
        <p-menubar [model]="items">
          <ng-template let-item pTemplate="item">
            <li class="p-menuitem" [ngClass]="{ open: item.isOpen }">
              <a
                *ngIf="!item.separator"
                (click)="
                  item.items
                    ? toggleSubmenu(item)
                    : item.command
                    ? item.command()
                    : null
                "
                class="p-menuitem-link gap-2"
                [ngClass]="{ active: activeSection === item.label }"
              >
                <span
                  class="p-menuitem-text"
                  [ngClass]="{ active: activeSection === item.label }"
                >
                  {{ item.label }}
                </span>
                <i
                  *ngIf="item.items"
                  class="pi flex align-items-center"
                  [ngClass]="item.isOpen ? 'pi-angle-up' : 'pi-angle-down'"
                ></i>
              </a>

              <ul *ngIf="item.isOpen" class="p-submenu-list">
                <li *ngFor="let subItem of item.items">
                  <a
                    (click)="subItem.command ? subItem.command() : null"
                    class="p-menuitem-link"
                  >
                    {{ subItem.label }}
                  </a>
                </li>
              </ul>
            </li>
          </ng-template>
        </p-menubar>
      </div>
    </ng-container>

    <!-- Menú para usuarios autenticados -->
    <div
      class="menu-right"
      [ngClass]="{ 'menu-with-border': !user && !tokenStorage }"
    >
      <p-menubar [model]="user || tokenStorage ? itemsSession : itemsRights">
        <ng-template let-item pTemplate="item">
          <li class="p-menuitem" [ngClass]="{ open: item.isOpen,'hide-on-large': item.label === 'Cerrar Sesión'}">
            <a
              *ngIf="!item.separator"
              (click)="
                item.items
                  ? toggleSubmenu(item)
                  : item.command
                  ? item.command()
                  : null
              "
              class="p-menuitem-link gap-2"
              [ngClass]="{ active: activeSection === item.label }"
            >
              <span
                class="p-menuitem-text"
                [ngClass]="{ active: activeSection === item.label }"
              >
                {{ item.label }}
              </span>
              <i
                *ngIf="item.items"
                class="pi flex align-items-center"
                [ngClass]="item.isOpen ? 'pi-angle-up' : 'pi-angle-down'"
              ></i>
            </a>

            <!-- Submenú -->
            <ul *ngIf="item.isOpen" class="p-submenu-list">
              <li *ngFor="let subItem of item.items">
                <a
                  (click)="subItem.command ? subItem.command() : null"
                  class="p-menuitem-link"
                >
                  {{ subItem.label }}
                </a>
              </li>
            </ul>
          </li>
        </ng-template>

        <!-- Elementos adicionales como avatar y redes sociales -->
        <ng-template pTemplate="end">
          <div *ngIf="user" class="btn-user flex align-items-center gap-2">
            <div
              class="border-user flex align-items-center justify-content-center gap-2"
              (click)="toggleDropdown()"
            >
              <p-avatar
                image="https://primefaces.org/cdn/primeng/images/demo/avatar/amyelsner.png"
                shape="circle"
              ></p-avatar>
              <div class="flex gap-2">
                <span class="poppins-semibold user-fullname">{{
                  dataUser?.firstName + " " + dataUser?.lastName
                }}</span>
                <i
                  class="pi flex align-items-center"
                  [ngClass]="isUserMenuOpen ? 'pi-angle-up' : 'pi-angle-down'"
                ></i>
              </div>
              <div
                *ngIf="isUserMenuOpen"
                class="absolute top-full bg-white shadow rounded"
              >
                <a class="item-logout cursor-pointer" (click)="logout()">
                  Cerrar sesión
                </a>
              </div>
            </div>

            <!-- Iconos de redes sociales -->
            <ul class="flex flex-row align-items-center gap-2 none-list">
              <li>
                <a
                  href="https://www.facebook.com/RiberaDelRioClubResort/?locale=es_LA"
                  target="_blank"
                >
                  <img
                    src="/assets/images/svg/icon-facebook.svg"
                    alt="Facebook"
                  />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/riberadelrioclubresort6"
                  target="_blank"
                >
                  <img
                    src="/assets/images/svg/icon-instagram.svg"
                    alt="Instagram"
                  />
                </a>
              </li>
              <li>
                <img src="/assets/images/svg/no_pets.svg" alt="no pets" />
              </li>
            </ul>
          </div>
        </ng-template>
      </p-menubar>
    </div>
  </div>
</div>
