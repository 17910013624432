import { ChangeDetectorRef, Component } from '@angular/core';
import { MenubarModule } from 'primeng/menubar';
import { MenuItem } from 'primeng/api';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { LoginService } from '../../services/login.service';
import { GeneralService } from '../../services/general.service';
import { AvatarModule } from 'primeng/avatar';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [MenubarModule, CommonModule, ProgressSpinnerModule, AvatarModule],
  templateUrl: './navbar-promotor.component.html',
  styleUrl: './navbar-promotor.component.scss',
})
export class NavbarComponent {
  items: MenuItem[] | undefined;
  itemsSession: MenuItem[] | undefined;
  activeSection: string | undefined;
  isLoading: boolean = false;
  user: any;
  tokenStorage: any;
  dataUser: any;
  previousUserData: any;
  isDropdownOpen: boolean = false;
  userSubscription!: Subscription;

  constructor(
    private router: Router,
    private loginService: LoginService,
    private generalService: GeneralService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.initializeMenuItems(); // Inicializa los menús sin sesión
    this.getTokenStorage(); // Recupera el token y, si existe, actualiza el estado del usuario

    this.loginService.userSubject.subscribe((res) => {
      this.user = res;
      this.tokenStorage = this.generalService.getKey('token');
      if (this.isUserLoggedIn()) {
        this.isLoading = true;
        this.updateMenuItems();

        if (!this.user) {
          this.generalService.set('user', 'false');
          this.getDataUser();
          console.log('El usuario no está disponible, actualizando datos...');
        }
        else {
          this.generalService.set('user', 'true');
          this.getDataUser();
          this.updateMenuItems();
        }
      }
      else {
        this.updateMenuItems();
      }
    });

    // this.userSubscription = this.generalService.user$.subscribe((user) => {
    //   console.log('USER', user)
    //   if (user && !this.isEqual(user, this.previousUserData)) {
    //     this.previousUserData = user;
    //     console.log('PREVIOUSUSERDATA', this.previousUserData);
    //     this.getDataUser();
    //   }
    // });

    const storedOption = this.generalService.getKey('selectedOption');
    this.activeSection = storedOption ? storedOption : 'Disponibles';
    this.updateMenuItems();
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  private isUserLoggedIn(): boolean {
    const token = this.generalService.getKey('token');
    return token !== undefined && token !== null;
  }

  private isEqual(obj1: any, obj2: any): boolean {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  }

  initializeMenuItems() {
    this.items = [
      {
        label: 'Alojamiento',
        items: [
          {
            label: 'Departamentos',
            command: () => this.onMenuItemClick('Departamentos'),
          },
          {
            label: 'Habitaciones',
            command: () => this.onMenuItemClick('Habitaciones'),
          },
          {
            label: 'Suits',
            command: () => this.onMenuItemClick('Suits'),
          },
        ],
      },
      {
        label: 'Restaurante',
        command: () => this.onMenuItemClick('Restaurantes'),
      },
      {
        label: 'Experiencia',
        items: [
          {
            label: 'Fullday',
            command: () => this.onMenuItemClick('Fullday'),
          },
        ],
      },
      {
        label: 'Eventos',
        command: () => this.onMenuItemClick('Eventos'),
      },
      {
        label: 'Nosotros',
        command: () => this.onMenuItemClick('Nosotros'),
      },
      {
        label: 'Contacto',
        command: () => this.onMenuItemClick('Contacto'),
      },
      {
        label: 'Iniciar sesión',
        command: (event) => this.onLoginClick(),
      },
      {
        label: 'Reservas',
        command: (event) => this.onMenuItemClick('Reservas'),
      },
    ];
    this.itemsSession = [
      {
        label: 'Inicio',
        command: () => this.onMenuItemClick('Inicio'),
      },
      {
        label: 'Mis datos',
        
      },
      {
        label: 'Reservas',
        items: [
          {
            label: 'Disponibles',
            command: () => this.onMenuItemClick('Disponibles'),
          },
          {
            label: 'Reservados',
            command: () => this.onMenuItemClick('Reservados'),
          },
          {
            label: 'Históricos',
            command: () => this.onMenuItemClick('Históricos'),
          },
        ],
      },
      {
        label: 'Beneficios',
      },
      {
        label: 'Mis puntos',
      },
      // {
      //   label: 'Cerrar sesión',
      //   command: (event) => this.logout(),
      // },
    ];
  }

  getTokenStorage() {
    if (typeof window !== 'undefined' && window.sessionStorage) {
      this.tokenStorage = sessionStorage.getItem('tokenP');
      if (this.tokenStorage) {
        this.getDataUser(); // Llama a getDataUser inmediatamente para actualizar el estado de usuario
      } else {
        this.user = null; // Se asegura de que user se establezca en null si no hay token
      }
    } else {
      console.warn('sessionStorage is not available');
      this.tokenStorage = null;
      this.user = null; // Establece user como null si sessionStorage no está disponible
    }
    this.updateMenuItems(); // Actualiza el menú después de manejar el token y usuario
  }

  updateMenuItems() {
    console.log('user', this.user)
    console.log('tokenStorage', this.tokenStorage);
    if (this.user || this.tokenStorage) {
      this.items = this.itemsSession;
    } else if (this.user == null && this.tokenStorage == null) {
      this.initializeMenuItems();
    }
    // Fuerza la detección de cambios para que el menú se actualice
    this.cdr.detectChanges();
  }

  onLoginClick() {
    this.isLoading = true;
    this.router.navigate(['/login']).then(() => {
      this.isLoading = false;
      setTimeout(() => {
        document.documentElement.scrollTop = 0;
        document.body.scrollTop = 0;
      }, 100);
    });
    /*  setTimeout(() => {
       this.router.navigate(['/login']).then(() => {
         this.isLoading = false;
       });
     }, 200); */
  }

  logout() {
    this.isDropdownOpen = false;
    this.isLoading = true;

    // Actualiza el estado del usuario a null inmediatamente
    this.loginService.userSubject.next(null);
    this.user = null;
    this.tokenStorage = null;

    // Limpia el almacenamiento local y sessionStorage
    this.clearStorage();

    // Redirige al home primero antes de recargar la página
    this.router.navigate(['/']).then(() => {
      this.isLoading = false;

      // Ahora que hemos redirigido a home, recargamos la página
      window.location.reload();
    });
  }

  clearStorage() {
    if (typeof window !== 'undefined' && window.localStorage) {
      localStorage.clear();
      sessionStorage.clear();
      localStorage.removeItem('selectedOption');
    }
  }

  onLogoClick() {
    this.isLoading = true;
    this.router.navigate(['/']).then(() => {
      this.isLoading = false;
      setTimeout(() => {
        document.documentElement.scrollTop = 0;
        document.body.scrollTop = 0;
      }, 100);
    });
  }

  // onMenuItemClick(section: string) {
  //   this.activeSection = section;
  //   localStorage.setItem('selectedOption', section);
  //   this.generalService.updateSelectedOption(section);
  //   if (section === 'Disponibles') {
  //     this.router.navigate(['/bookings/disponibles']);
  //   } else if (section === 'Reservados') {
  //     this.router.navigate(['/bookings/reservados']);
  //   } else if (section === 'Históricos') {
  //     this.router.navigate(['/bookings/históricos']);
  //   } else if (section === 'Inicio') {
  //     this.router.navigate(['/welcome']);
  //   }

  //   if (section === 'Departamentos') {
  //     this.router.navigate(['/accommodation/departments'])
  //   } else if (section === 'Habitaciones'){
  //     this.router.navigate(['/accommodation/rooms'])
  //   } else if (section === 'Suits') {
  //     this.router.navigate(['/accommodation/suits'])
  //   }

  //   if ( section === 'Reservas') {
  //     console.log("Section: ", section);
  //     this.router.navigate(['/bookings/disponibles'])
  //   }
  // }

  onMenuItemClick(section: string) {
    this.isLoading = true; // Mostrar el loader
    this.activeSection = section;

    let navigationPath = '';

    // Verificar si es la opción de 'Reservas' para asignar 'Disponibles'
    if (section === 'Reservas') {
      // Al seleccionar 'Reservas', redirigir a 'Disponibles'
      localStorage.setItem('selectedOption', 'Disponibles');
      this.generalService.updateSelectedOption('Disponibles');
      navigationPath = '/bookings/disponibles';
    } else {
      // Para las demás opciones, almacenar y navegar normalmente
      localStorage.setItem('selectedOption', section);
      this.generalService.updateSelectedOption(section);

      if (section === 'Disponibles') {
        navigationPath = '/bookings/disponibles';
      } else if (section === 'Reservados') {
        navigationPath = '/bookings/reservados';
      } else if (section === 'Históricos') {
        navigationPath = '/bookings/históricos';
      } else if (section === 'Inicio') {
        navigationPath = '/welcome';
      } else if (section === 'Departamentos') {
        navigationPath = '/accommodation/departments';
      } else if (section === 'Habitaciones') {
        navigationPath = '/accommodation/rooms';
      } else if (section === 'Suits') {
        navigationPath = '/accommodation/suits';
      } else if (section === 'Restaurantes') {
        navigationPath = '/restaurant';
      } else if (section === 'Fullday') {
        navigationPath = '/experience';
      } else if (section === 'Eventos') {
        navigationPath = '/event';
      } else if (section === 'Nosotros') {
        navigationPath = '/about';
      } else if (section === 'Contacto') {
        navigationPath = '/contact';
      } else if (section === 'Perfil') {
        navigationPath = '/profile';
      } 
    }

    // Navegar a la sección y ocultar el loader cuando termine
    this.router.navigate([navigationPath]).then(() => {
      this.isLoading = false;
      setTimeout(() => {
        document.documentElement.scrollTop = 0;
        document.body.scrollTop = 0;
      }, 100);
    });


  }

  getDataUser() {
    if (!this.tokenStorage) {
      this.dataUser = null;
      this.user = null; // Asegúrate de que user sea null si no hay token
      this.updateMenuItems();
      this.cdr.detectChanges();
      return;
    }

    this.generalService.getApiUsers('find/my-data').subscribe(
      (response) => {
        this.dataUser = response;
        this.user = response;
        this.isLoading = false;
        this.updateMenuItems();
        this.cdr.detectChanges();
      },
      (error) => {
        console.error('Error fetching data user:', error);
        this.user = null;
        this.isLoading = false;
        this.updateMenuItems(); // Asegúrate de que el menú refleje el cierre de sesión
        this.cdr.detectChanges();
      }
    );
  }
}
