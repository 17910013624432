import { ApplicationConfig, DEFAULT_CURRENCY_CODE, LOCALE_ID } from '@angular/core';
import { provideRouter, RouterModule } from '@angular/router';
import { provideAnimations } from '@angular/platform-browser/animations';

import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { provideHttpClient, withFetch } from '@angular/common/http';
import { provideOAuthClient } from 'angular-oauth2-oidc';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { DatePipe, registerLocaleData } from '@angular/common';
import { DialogService } from 'primeng/dynamicdialog';
import { ScrollService } from './services/scroll.service';
import localeEsPe from '@angular/common/locales/es-PE';
registerLocaleData(localeEsPe, 'es-PE');

export const appConfig: ApplicationConfig = {
  providers: [
    MessageService,
    DialogService,
    ScrollService,
    PrimeNGConfig,
    provideRouter(routes),
    provideClientHydration(),
    provideAnimations(),
    provideOAuthClient(),
    provideHttpClient(withFetch()),
    DatePipe,
    { provide: LOCALE_ID, useValue: 'es-PE' }, // Configurar el locale globalmente
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'S/.' }, // Configurar la moneda globalmente

  ],
};
